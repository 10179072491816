import React, { useState, ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import ExpandCollapseIcon from '@src/components/ExpandCollapseIcon'
import { logExpandCollapseEvent } from '@utils/analytics/facets'
import FormattedFacetMessage from './FormattedFacetMessage'

interface FacetGroupCollapseProps {
  facetKey: string
  defaultCollapse: boolean
  children: ReactNode
  isSubstanceFacets?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  facetGroupHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerBorderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  facetGroupLabel: {
    wordBreak: 'break-all',
    fontWeight: 'bold',
  },
  substanceFacetGroupLabel: {
    wordBreak: 'break-all',
    fontFamily: 'Lato',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('sm')]: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  facetGroupCollapse: {
    display: 'flex',
    flexShrink: 0,
    marginRight: theme.spacing(-2.5),
  },
  collapseIcon: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.primary.main,
  },
}))

const FacetGroupCollapse: React.FC<FacetGroupCollapseProps> = ({
  defaultCollapse = false,
  facetKey,
  children,
  isSubstanceFacets,
}) => {
  const classes = useStyles()

  const [showGroup, setShowGroup] = useState(defaultCollapse)

  return (
    <>
      <div
        className={clsx(
          classes.facetGroupHeader,
          isSubstanceFacets ? classes.headerBorderBottom : ''
        )}
      >
        <span
          className={
            isSubstanceFacets
              ? classes.substanceFacetGroupLabel
              : classes.facetGroupLabel
          }
        >
          {facetKey && <FormattedFacetMessage id={facetKey.toUpperCase()} />}
        </span>
        <span className={classes.facetGroupCollapse}>
          <ExpandCollapseIcon
            className={classes.collapseIcon}
            onClickHandler={() => {
              setShowGroup(!showGroup)
              logExpandCollapseEvent(
                !showGroup ? 'expand' : 'collapse',
                facetKey
              )
            }}
            showGroup={showGroup}
            id={
              showGroup
                ? `facet-group-${facetKey}-hide`
                : `facet-group-${facetKey}-show`
            }
          />
        </span>
      </div>
      {showGroup && children}
    </>
  )
}

export default FacetGroupCollapse
