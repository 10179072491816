// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type StructureSearchQueryVariables = Types.Exact<{
  type: Types.StructureSearchType;
  term: Types.Scalars['String'];
  format: Types.StructureSearchFormat;
  sort?: Types.InputMaybe<Types.Sort>;
  pagination?: Types.Pagination;
  filters?: Types.InputMaybe<Types.StructureSearchFilters>;
  selectedFacets?: Types.InputMaybe<Array<Types.FacetInput> | Types.FacetInput>;
  catalogType?: Types.InputMaybe<Types.CatalogType>;
}>;


export type StructureSearchQuery = { __typename?: 'Query', getStructureSearchResults: { __typename?: 'StructureSearchResults', metadata: { __typename?: 'SearchResultsMetadata', itemCount?: number | null, setsCount?: number | null, page: number, perPage: number, numPages: number }, items: Array<{ __typename?: 'Substance', _id: string, id: string, name: string, synonyms: Array<string>, empiricalFormula?: string | null, linearFormula?: string | null, molecularWeight?: string | null, casNumber?: string | null, aliases: Array<{ __typename?: 'Alias', key: string, label: string, value: string }>, images: Array<{ __typename?: 'CatalogImage', sequence?: number | null, altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string }>, products: Array<{ __typename?: 'Product', productNumber: string, productKey: string, cardCategory?: string | null, speciesReactivity: Array<string>, description?: string | null, sdsLanguages: Array<string | null>, sdsPnoKey: string, similarity?: string | null, paMessage?: string | null, features: Array<string>, catalogId?: Types.CatalogType | null, erp_type?: Array<string> | null, materialIds: Array<string>, cardAttribute?: { __typename?: 'CardAttribute', citationCount?: string | null, application: Array<string> } | null, attributes: Array<{ __typename?: 'ProductAttribute', key: string, label: string, values: Array<string> }>, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null } }> }>, facets: Array<{ __typename?: 'Facet', key: string, numToDisplay: number, isHidden?: boolean | null, isCollapsed: boolean, multiSelect: boolean, prefix?: string | null, options: Array<{ __typename?: 'FacetOptions', value: string, count: number }> }> } };


export const StructureSearchDocument = gql`
    query StructureSearch($type: StructureSearchType!, $term: String!, $format: StructureSearchFormat!, $sort: Sort, $pagination: Pagination! = {}, $filters: StructureSearchFilters, $selectedFacets: [FacetInput!], $catalogType: CatalogType) {
  getStructureSearchResults(input: {type: [$type], term: $term, format: $format, sort: $sort, pagination: $pagination, filters: $filters, facets: $selectedFacets, catalogType: $catalogType}) {
    metadata {
      itemCount
      setsCount
      page
      perPage
      numPages
    }
    items {
      _id
      id
      name
      synonyms
      empiricalFormula
      linearFormula
      molecularWeight
      aliases {
        key
        label
        value
      }
      images {
        sequence
        altText
        smallUrl
        mediumUrl
        largeUrl
      }
      casNumber
      products {
        productNumber
        productKey
        cardCategory
        cardAttribute {
          citationCount
          application
        }
        attributes {
          key
          label
          values
        }
        speciesReactivity
        brand {
          key
          erpKey
          name
          color
        }
        description
        sdsLanguages
        sdsPnoKey
        similarity
        paMessage
        features
        catalogId
        erp_type
        materialIds
      }
    }
    facets {
      key
      numToDisplay
      isHidden
      isCollapsed
      multiSelect
      prefix
      options {
        value
        count
      }
    }
  }
}
    `;
export type StructureSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StructureSearchQuery, StructureSearchQueryVariables>, 'query'> & ({ variables: StructureSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StructureSearchComponent = (props: StructureSearchComponentProps) => (
      <ApolloReactComponents.Query<StructureSearchQuery, StructureSearchQueryVariables> query={StructureSearchDocument} {...props} />
    );
    

/**
 * __useStructureSearchQuery__
 *
 * To run a query within a React component, call `useStructureSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useStructureSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStructureSearchQuery({
 *   variables: {
 *      type: // value for 'type'
 *      term: // value for 'term'
 *      format: // value for 'format'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      selectedFacets: // value for 'selectedFacets'
 *      catalogType: // value for 'catalogType'
 *   },
 * });
 */
export function useStructureSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StructureSearchQuery, StructureSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StructureSearchQuery, StructureSearchQueryVariables>(StructureSearchDocument, options);
      }
export function useStructureSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StructureSearchQuery, StructureSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StructureSearchQuery, StructureSearchQueryVariables>(StructureSearchDocument, options);
        }
export type StructureSearchQueryHookResult = ReturnType<typeof useStructureSearchQuery>;
export type StructureSearchLazyQueryHookResult = ReturnType<typeof useStructureSearchLazyQuery>;
export type StructureSearchQueryResult = ApolloReactCommon.QueryResult<StructureSearchQuery, StructureSearchQueryVariables>;