import { useSessionStorage, sessionStorage } from 'react-storage'
import { StructureSearchInitialQueryVars } from './StructureSearchDraw'
const RECENT_STRUCTURE_SEARCHES_STORAGE_KEY = 'recentStructures'
export const MAX_RECENT_SEARCHES = 8

export function useRecentStructureSearches(): StructureSearchInitialQueryVars[] {
  const recentStructures = useSessionStorage<StructureSearchInitialQueryVars[]>(
    RECENT_STRUCTURE_SEARCHES_STORAGE_KEY,
    []
  )
  return Array.isArray(recentStructures) ? recentStructures : []
}

export function addRecentStructureSearch(
  structureQuery: StructureSearchInitialQueryVars
): void {
  let recentStructures = sessionStorage.getItem<
    StructureSearchInitialQueryVars[]
  >(RECENT_STRUCTURE_SEARCHES_STORAGE_KEY)

  if (!Array.isArray(recentStructures)) {
    recentStructures = []
  } else if (recentStructures.length >= MAX_RECENT_SEARCHES) {
    recentStructures.shift()
  }
  recentStructures.push(structureQuery)

  return sessionStorage.setItem(
    RECENT_STRUCTURE_SEARCHES_STORAGE_KEY,
    recentStructures
  )
}

export function mostRecentStructureSearch(
  searches: StructureSearchInitialQueryVars[]
) {
  return searches[searches.length - 1]
}
