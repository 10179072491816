import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'
import { FieldProps } from 'formik'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkbox: {
    borderRadius: 6,
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.grey[100]}`,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: `2px auto ${theme.palette.secondary.dark}`,
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  selectedCheckbox: {
    border: '2px solid transparent',
    backgroundColor: `${theme.palette.secondary.dark}`,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
}))

const StructureSearchCustomCheckbox: React.FC<FieldProps> = ({ field }) => {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root}
      inputProps={{
        'aria-label': 'stereo-checkbox',
      }}
      checkedIcon={
        <span className={clsx(classes.checkbox, classes.selectedCheckbox)} />
      }
      icon={<span className={classes.checkbox} />}
      checked={!!field.value}
      {...field}
    />
  )
}

export default StructureSearchCustomCheckbox
