import React, { useState, useRef, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl'
import { GraphQLError } from 'graphql'
import { ApolloError } from 'apollo-boost'
import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, Paper, Theme } from '@material-ui/core'
import {
  searchResultsRoute,
  structureSearchDrawRoute,
  useRouter,
} from '@src/routes'
import {
  getValidSearch,
  SearchFocusType,
  structureFilterArrayToObject,
} from '@utils/searchUtils'
import messages from '@utils/messages'
import {
  StructureSearchFormat,
  StructureSearchFilters,
  StructureSearchType,
  CatalogType,
  Sort,
} from '@src/types/graphql-types'
import { useStructureSearchLazyQuery } from '@src/queries/StructureSearchQuery.generated'
import SearchResultsError from '@src/components/SearchResultsError'
import {
  addRecentStructureSearch,
  useRecentStructureSearches,
  mostRecentStructureSearch,
} from './structureSessionStorageUtils'
import StructureSearchForm from './StructureSearchForm'
import MarvinJSFaqs from './MarvinJSFaqs'
import { sendStructureSearchEvent } from '@utils/analytics'

const MarvinJS = dynamic(() => import('./MarvinJS'), {
  ssr: false,
})

const useStyles = makeStyles((theme: Theme) => ({
  searchContainer: {
    marginBottom: theme.spacing(10),
    padding: theme.spacing(4),
  },
  searchBody: {
    padding: theme.spacing(1, 6),
  },
  structureSearchContainer: {
    position: 'relative',
    paddingTop: theme.spacing(6),
  },
  editor: {
    overflow: 'hidden',
    width: '100%',
    minHeight: '450px',
    border: '1px solid transparent',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  loadingIndicator: {
    position: 'absolute',
    top: '45%',
    left: '45%',
  },
}))

const handleSimliarityPercentageChange = (e) => {
  if (!parseInt(e.target.value)) e.target.value = ''
  else {
    let newVal = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
    newVal = parseInt(newVal) > 100 ? '100' : newVal
    e.target.value = newVal
  }
}

const handleRadioChange = (e, setFieldValue, similarityPercentage) => {
  setFieldValue('searchType', e.target.value)
  if (e.target.value !== 'similarity' && similarityPercentage) {
    setFieldValue('similarityPercentage', '')
  } else if (e.target.value === 'similarity') {
    setFieldValue('similarityPercentage', '70')
  }
}

export interface StructureSearchInitialQueryVars {
  term?: string
  type?: string
  format?: StructureSearchFormat
  filters?: StructureSearchFilters
  image?: string
  catalogType?: CatalogType.Buildingblocks
}

export interface BasicErrorMessage {
  message: string
}
interface StructureSearchDrawProps {
  showEditModal?: boolean
  setShowEditModal?: (boolean) => void
  recentSearchSelection?: StructureSearchInitialQueryVars
}

const StructureSearchDraw: React.FC<StructureSearchDrawProps> = ({
  showEditModal,
  setShowEditModal,
  recentSearchSelection,
}) => {
  const router = useRouter()
  const classes = useStyles()
  // need to use filters from the router, filter data does not persist on the useSearchQuery hook when on the noResults page.
  const filters = router?.query?.filters
  const marvinSketch = useRef()
  const { formatMessage } = useIntl()
  const [searchVars, setSearchVars] = useState<StructureSearchInitialQueryVars>(
    {}
  )
  const [formikInitialValues, setFormikInitialValues] = useState({
    searchType: StructureSearchType.Substructure,
    similarityPercentage: '',
  })
  const [hasNoResults, setHasNoResults] = useState(false)
  const [marvinSketchReady, setMarvinSketchReady] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [stateErrors, setStateErrors] = useState<
    ApolloError | GraphQLError | BasicErrorMessage | undefined
  >(undefined)
  const [getStructureSearchResults, { data, loading, error }] =
    useStructureSearchLazyQuery()
  const isModal = Boolean(showEditModal)
  let anyLoading = loading
  const queryResults = data?.getStructureSearchResults
  const filtersObject = structureFilterArrayToObject(filters)
  const initialStereoValue = filtersObject?.stereo
    ? { stereo: filtersObject.stereo }
    : {}
  const initializeSimilarityValue = (searchType, filters) =>
    searchType === StructureSearchType.Similarity && filters.cutoff
      ? `${filters.cutoff}`
      : ''

  // update initial formik values if url params or recent search selected
  useEffect(() => {
    if (router.query.type) {
      setFormikInitialValues({
        searchType: router.query.type as StructureSearchType,
        similarityPercentage: initializeSimilarityValue(
          router.query.type,
          filtersObject
        ),
        ...initialStereoValue,
      })
    } else if (!isEmpty(recentSearchSelection) && recentSearchSelection?.type) {
      setFormikInitialValues({
        searchType: recentSearchSelection.type as StructureSearchType,
        similarityPercentage: initializeSimilarityValue(
          recentSearchSelection?.type,
          recentSearchSelection.filters
        ),
        ...(router?.query?.filters
          ? initialStereoValue
          : { stereo: recentSearchSelection.filters?.stereo }),
      })
    }
    // TODO: need a test before we refactor these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query.type, recentSearchSelection?.term])

  /* need to reset query error/no results state locally so
  user can enter new search. Apollo doesn't currently have
  a clear way to reset the query so we use state*/
  //set state errors with any received from api call
  useEffect(() => {
    if (error) setStateErrors(error)
  }, [error])

  const currentStructureSearch = mostRecentStructureSearch(
    useRecentStructureSearches()
  )

  //import current structure via session storage or selected recent structure to editor
  useEffect(() => {
    const importFormat = 'mol'
    // When this component is used as in the edit query modal pull the current structure search
    if (marvinSketchReady && currentStructureSearch && isModal) {
      //@ts-ignore marvinSketchReady verifies marvinSketch.current
      marvinSketch.current.importStructure(
        importFormat,
        currentStructureSearch.term
      )
      // else if there is a recent structure search we load that instead
    } else if (marvinSketchReady && recentSearchSelection) {
      //@ts-ignore marvinSketchReady verifies marvinSketch.current
      marvinSketch.current.importStructure(
        importFormat,
        recentSearchSelection.term
      )
    }
    const setSearchDisabled = () => {
      //@ts-ignore marvinSketchReady verifies marvinSketch.current
      const empty = marvinSketch.current.isEmpty()
      if (empty) {
        setButtonDisabled(true)
      } else {
        setButtonDisabled(false)
      }
    }
    if (marvinSketchReady && marvinSketch.current !== undefined) {
      setSearchDisabled()
      //@ts-ignore marvinSketchReady verifies marvinSketch.current
      marvinSketch.current.on('molchange', function () {
        setSearchDisabled()
      })
    }
    // TODO: need a test before we refactor these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStructureSearch, marvinSketchReady, recentSearchSelection])

  useEffect(() => {
    if (queryResults?.items && queryResults.items.length === 0)
      setHasNoResults(true)
  }, [queryResults])

  const captureSketcherContent = async () => {
    if (marvinSketchReady) {
      //@ts-ignore marvinSketchReady verifies marvinSketch.current
      const molFile = await marvinSketch.current
        .exportStructure('mol')
        .catch((e) => alert(`Failed to process structure image. Error: ${e}`))

      // additional settings options: https://marvinjs-demo.chemaxon.com/latest/jsdoc.html#marvin.ImageExporter.mrvToDataUrl(String,String,JavaScriptObject)
      //@ts-ignore
      const structureImage = await marvinSketch.current
        .exportStructure('png', {
          imageType: 'image/png',
        })
        .catch((e) => alert(`Failed to save structure image. Error: ${e}`))
      return { searchTerm: molFile, image: structureImage }
    } else {
      alert('Error, marvin sketch not currently functional')
      return {}
    }
  }

  const handleSearch = async (values) => {
    const sketcherContent = await captureSketcherContent()
    const emptySketcherSearchTermLength = 82
    if (sketcherContent.searchTerm.length <= emptySketcherSearchTermLength) {
      setStateErrors({
        message: formatMessage(messages.NO_SKETCHER_CONTENT_CAPTURED),
      })
      return
    }
    const sketcherImage = sketcherContent?.image
    // slices last 30 chars from encoded image to use as a key reference to full image
    const sketcherImageRef = sketcherImage.slice(-30)
    const variables = {
      type: values.searchType,
      term: sketcherContent?.searchTerm,
      format: StructureSearchFormat.Molfile,
      image: sketcherImageRef,
    }

    /* if user searches exact same structure multiple times,
    ie: same structure but different search type input,
    we only need to store the image 1x
    */
    if (!sessionStorage.getItem(sketcherImageRef)) {
      sessionStorage.setItem(sketcherImageRef, sketcherImage)
    }

    const validCutoff = Number(values.similarityPercentage)
    const addCutoffIfValid = validCutoff ? { cutoff: validCutoff } : {}
    const filters = {
      ...addCutoffIfValid,
      stereo: !!values.stereo,
      /*if we end up implementing # searchResults/maxhits it will go here
        maxhits: values.totalHits
      */
    }
    const searchVars = { ...variables, filters }

    setSearchVars(searchVars)
    addRecentStructureSearch(searchVars)
    getStructureSearchResults({
      variables: {
        pagination: {
          page: 1,
          perPage: 30,
        },
        selectedFacets: [],
        sort: Sort.Relevance,
        type: values.searchType,
        term: sketcherContent?.searchTerm,
        format: StructureSearchFormat.Molfile,
        filters,
      },
    })

    const gaSearchType = values.similarityPercentage
      ? `${values.searchType} - ${values.similarityPercentage}`
      : values.searchType
    sendStructureSearchEvent({
      searchTerm: 'structure_search',
      searchAutoSuggest: '',
      searchAutoSuggestTerm: '',
      searchType: gaSearchType,
      searchComponent: 'structure search',
      searchErrorMessage: '',
    })
  }

  const resetDrawState = (resetForm) => {
    resetForm({ values: formikInitialValues })
    //@ts-ignore  marvinSketchReady verifies marvinSketch.current
    marvinSketchReady && marvinSketch.current.clear()
    if (stateErrors) setStateErrors(undefined)
    setButtonDisabled(true)
  }

  if (hasNoResults && !anyLoading && !isEmpty(searchVars)) {
    const queryParams = `${getValidSearch({
      term: 'structure-search',
      focus: SearchFocusType.StructureSearch,
      type: searchVars.type,
      format: searchVars.format,
      filters: searchVars.filters,
      image: searchVars.image,
    })}`
    setSearchVars({})
    router.push(`${structureSearchDrawRoute.noResults()}${queryParams}`)

    if (isModal && setShowEditModal) {
      setShowEditModal(false)
    }
  }

  if (queryResults && queryResults.items.length > 0 && searchVars) {
    anyLoading = true

    const queryParams = `${getValidSearch({
      // until we can find a way to persist mol files on the backend
      // we will have to use this unorthodox work around.
      // the route on the other side of this transition will utilize
      // the `useRecentStructureSearch` hook until a backend solution
      // can  be found. in the interim we will provide a placehorder
      // for `term`. otherwise the transition is rejected.
      term: 'structure-search',
      focus: SearchFocusType.StructureSearch,
      type: searchVars.type,
      format: searchVars.format,
      filters: searchVars.filters,
      image: searchVars.image,
    })}`

    router.push(
      `${searchResultsRoute.searchResults('structure-search')}${queryParams}`
    )
    if (isModal && setShowEditModal) {
      setShowEditModal(false)
    }
  }

  return (
    <Paper className={classes.searchContainer} square elevation={0}>
      <div className={classes.searchBody}>
        <div className={classes.structureSearchContainer}>
          {anyLoading && (
            <CircularProgress className={classes.loadingIndicator} />
          )}
          {stateErrors ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              className={classes.editor}
            >
              <SearchResultsError error={stateErrors} />
            </Box>
          ) : (
            <Paper elevation={2} square>
              <MarvinJS
                marvinStyle={classes.editor}
                marvinSketch={marvinSketch}
                setMarvinSketchReady={setMarvinSketchReady}
              />
            </Paper>
          )}
          <StructureSearchForm
            initialValues={formikInitialValues}
            onSubmit={handleSearch}
            resetDisabled={anyLoading}
            searchDisabled={
              !marvinSketchReady ||
              anyLoading ||
              !!stateErrors ||
              buttonDisabled
            }
            isModal={isModal}
            resetDrawState={resetDrawState}
            handleRadioChange={handleRadioChange}
            handleSimliarityPercentageChange={handleSimliarityPercentageChange}
          />
          {!isModal && <MarvinJSFaqs />}
        </div>
      </div>
    </Paper>
  )
}

export default StructureSearchDraw
