import React from 'react'
import { useIntl } from 'react-intl'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles, Theme } from '@material-ui/core/styles'
import StructureSearchDraw from '../../StructureSearch/StructureSearchDraw'
import messages from '../messages'

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    background: theme.palette.background.grey,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  iconButton: {
    padding: 0,
  },
}))

interface StructureSearchEditModalProps {
  showEditModal: boolean
  setShowEditModal: (boolean) => void
}

const StructureSearchEditModal: React.FC<StructureSearchEditModalProps> = ({
  showEditModal,
  setShowEditModal,
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  return (
    <Dialog
      open={showEditModal}
      onClose={() => setShowEditModal(false)}
      fullWidth
      maxWidth={'md'}
      aria-labelledby="edit structure query"
    >
      <DialogTitle className={classes.titleContainer} id="edit structure query">
        <Box display="flex" width="100%" justifyContent="space-between">
          <h4 className={classes.title}>
            {formatMessage(messages.EDIT_QUERY)}
          </h4>
          <IconButton
            onClick={() => setShowEditModal(false)}
            aria-label="close"
            disableRipple={false}
            className={classes.iconButton}
          >
            <Close color="secondary" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent id="edit structure query content">
        <Box p={5}>
          <StructureSearchDraw
            setShowEditModal={setShowEditModal}
            showEditModal={showEditModal}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default StructureSearchEditModal
