import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import messages from '../messages'
import HandleMarkup from '@src/components/HandleMarkup'

export const formatFacetName = (id?: string): string => {
  if (id) {
    return id.toLowerCase().replace('facet_web_', '').replace(/_/g, ' ')
  }
  return ''
}

const FormattedFacetMessage: FC<{ id: string }> = ({ id }) => {
  const { formatMessage } = useIntl()
  if (!id) return null
  if (!messages[id]) {
    return <>{id.toLowerCase().replace('facet_web_', '').replace(/_/g, ' ')}</>
  }
  const message = formatMessage(messages[id])
  return <HandleMarkup value={message} />
}

export default FormattedFacetMessage
