import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link as MUILink } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    drawingTips: {
      marginTop: theme.spacing(7),
    },
    drawingTipsTitle: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    drawingTipsBody: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: '1.5rem',
    },
    dashList: {
      listStyle: 'none',
    },
    dashItem: {
      '&::before': {
        content: `'-'`,
        position: 'absolute',
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: '-30px',
      },
    },
    textLink: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }
})

const MarvinJSFaqs = () => {
  const classes = useStyles()

  return (
    <div className={classes.drawingTips}>
      {/* Todo: add in Tips if it is decided we need them or delete this placeholder code STRAT-1878
      <p className={classes.drawingTipsTitle}>
        Tips on how to use the drawing tool:
      </p>
      <ul className={classes.drawingTipsBody}>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus
          ac tortor eu porta. Nulla in venenatis purus. Praesent vestibulum quam
          a molestie cursus. Aliquam a molestie tortor.
        </li>
        <li>
          Aliquam vehicula congue interdum. Donec vulputate, enim at consectetur
          ullamcorper, lectus risus ultrices eros, sit amet dapibus ipsum elit
          eu orci.
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in
          venenatis purus
          <ul className={classes.dashList}>
            <li className={classes.dashItem}>
              Quisque ac viverra arcu. Fusce scelerisque maximus mi, sed luctus
              leo mollis in.
            </li>
            <li className={classes.dashItem}>
              {' '}
              Maecenas volutpat felis non ligula facilisis tincidunt.
            </li>
          </ul>
        </li>
        <li>*/}
      For more information, please visit{' '}
      <span>
        <MUILink
          color="secondary"
          href="https://docs.chemaxon.com/display/docs/Marvin+JS+FAQ"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={classes.textLink}
        >
          FAQs for MarvinJS
        </MUILink>
      </span>
      {/* </li>
      </ul> */}
    </div>
  )
}

export default MarvinJSFaqs
