import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@material-ui/core/Box'
import messages from '@utils/messages'
import { GraphQLError } from 'graphql'
import { ApolloError } from 'apollo-boost'
import { BasicErrorMessage } from '../../routes/StructureSearch/StructureSearchDraw'

interface ErrorProps {
  error?: ApolloError | GraphQLError | BasicErrorMessage
}
const SearchResultsError: React.FC<ErrorProps> = ({ error }) => (
  <Box
    borderRadius="borderRadius"
    border={1}
    display="flex"
    alignItems="center"
    flexDirection="column"
    borderColor="error.main"
    p="12px 20px"
    color="error.main"
  >
    <FormattedMessage {...messages.GENERIC_ERROR} />
    {error?.message && <p>{error.message}</p>}
  </Box>
)

export default SearchResultsError
