import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { structureSearchDrawRoute, useRouter } from '@src/routes'
import FacetGroupCollapse from '@src/routes/SearchResults/Facets/FacetGroupCollapse'
import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import messages from '../messages'
import StructureSearchEditModal from './StructureSearchEditModal'

const useStyles = makeStyles((theme: Theme) => ({
  editSearchContainer: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  structureCriteria: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
  },
  structureImageContainer: {
    margin: theme.spacing(2, 0, 4, 0),
    padding: theme.spacing(2, 0),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '4px',
    height: '95%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  structureImage: {
    minHeight: theme.typography.pxToRem(200),
    minWidth: theme.typography.pxToRem(200),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const StructureSearchModifyQuery = () => {
  const router = useRouter()
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [showEditModal, setShowEditModal] = useState(false)
  const [structureImage, setStructureImage] = useState<string | null>()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const imageKey = router.query.image
      const structureImage = sessionStorage.getItem(imageKey)
      setStructureImage(structureImage)
    }
  }, [router.query.image])

  return (
    <>
      {showEditModal && (
        <StructureSearchEditModal
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
        />
      )}
      <div className={classes.editSearchContainer}>
        <FacetGroupCollapse defaultCollapse facetKey={'STRUCTURE_CRITERIA'}>
          <div className={classes.structureCriteria}>
            <div className={classes.structureImageContainer}>
              <img
                src={structureImage || ''}
                alt={formatMessage(messages.STRUCTURE_QUERY_IMAGE_ALT)}
                className={classes.structureImage}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => setShowEditModal(true)}
              id="button--edit-structure-search-query"
            >
              {formatMessage(messages.EDIT_QUERY)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => router.push(structureSearchDrawRoute.index())}
              id="button--new-structure-search-query"
            >
              {formatMessage(messages.NEW_SEARCH)}
            </Button>
          </div>
        </FacetGroupCollapse>
      </div>
    </>
  )
}

export default StructureSearchModifyQuery
