import React from 'react'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '2rem',
  },
}))

interface ExpandCollapseIconProps {
  onClickHandler: () => void
  showGroup: boolean
  className?: string
  size?: 'small' | 'medium'
  id?: string
}

const ExpandCollapseIcon: React.FC<ExpandCollapseIconProps> = ({
  onClickHandler,
  showGroup,
  className,
  size = 'small',
  id,
}) => {
  const classes = useStyles()
  const ExpandCollapseIcon = showGroup ? ExpandLess : ExpandMore
  return (
    <IconButton
      id={id}
      onClick={onClickHandler}
      size={size}
      aria-label={showGroup ? 'Hide Group' : 'Show Group'}
    >
      <ExpandCollapseIcon className={className || classes.icon} />
    </IconButton>
  )
}

export default ExpandCollapseIcon
