import { isServer } from '@utils/isServer'
import { parseRegionalUrl } from '@utils/regional'
import { sendEvent } from '@utils/analytics/coreAnalytics'

/*
  Input type for all parameters a facet/filter object could take.
  This includes some future parameters not currently used but the analytics team envisions using.

  You'll notice a lot of explicitly 'undefined' properties.
  This is weird in a normal context but Google Analytics likes explicit undefined values for certain parameters.
  Usually these being parameters regularly set on one variant of the event, but not the other.

  So you need to set it 'undefined' instead of omitting the value entirely. 
*/
export interface AnalyticsFacetInputs {
  event?: string
  action: string
  detail?: undefined // Reserved for later use
  section?: undefined // Reserved for later use
  component: string
  element_type: string
  element_text?: string
  link_url?: undefined
  core_event?: 'yes' | 'no'
  user_detail?: undefined // Reserved for later use
  event_group?: undefined // Reserved for later use
  event_subroup?: undefined // Reserved for later use
  filter_name?: string
  filter_name_count?: number
  filter_category: string
  filter_existing?: string
  search_term?: string
  search_type?: string
  search_result_count?: number
  search_result_display?: string
}

/*
  For the returned payload certain fields need to change their type and optional vs required status.
  Instead of making two nearly identical objects it is easier to just omit to, then extend the omission.
*/
type FacetResponseDefinitions = Omit<
  AnalyticsFacetInputs,
  'event' | 'filter_existing | core_event'
>

interface BuiltFacetPayload extends FacetResponseDefinitions {
  event: string
  filter_existing: string | undefined
  core_event: 'yes' | 'no'
}

interface QueryParamsOutput {
  term?: string
  type?: string
  sort?: string
  page?: string
  [key: string]: string | undefined // Allows for other possible query parameters
}

const getQueryData = (queryString: string): QueryParamsOutput => {
  const queryParams = new URLSearchParams(queryString)

  const params: QueryParamsOutput = {}

  for (const [key, value] of queryParams.entries()) {
    params[key] = value
  }

  // Multiple 'facet' instances can exist in a URL.
  // This gets all of them and puts them into their own array.
  const urlFacets = queryParams.getAll('facet')
  params.facetList = urlFacets?.join(' | ')?.trim()

  return params
}

// For easy analysis we want the filter names and categories only in English using their key names from the translations API.
// For readability we strip some common prefixes that just add clutter, and swap _ with spaces.
const removePrefixes = (facetKey?: string): string => {
  if (!facetKey) {
    return ''
  }

  return facetKey
    .toLowerCase()
    .replaceAll('facet_web_', '')
    .replaceAll('facet', '')
    .replaceAll('_', ' ')
    .replaceAll(':', ' : ')
    .trim()
    .toLowerCase()
}

export const buildFacetEventPayload = (
  filterData: AnalyticsFacetInputs
): BuiltFacetPayload => {
  filterData.filter_category = removePrefixes(filterData.filter_category)

  const parsedFilterName = removePrefixes(filterData?.filter_name)
  filterData.filter_name = parsedFilterName || undefined

  if (filterData.element_text) {
    filterData.element_text = filterData.element_text.toLowerCase()
  }

  // Handle building and formatting of search terms, types, and counts from search page URL.
  const currentUrl = !isServer()
    ? parseRegionalUrl(`${window.location.pathname}${window.location.search}`)
    : null

  const queryData = currentUrl?.query
    ? getQueryData(currentUrl.query)
    : undefined

  filterData.search_term = filterData?.search_term || queryData?.term
  filterData.search_type = filterData?.search_type || queryData?.type

  filterData.filter_existing =
    filterData?.filter_existing || queryData?.facetList || undefined

  filterData.search_result_display =
    filterData?.search_result_display ||
    `page ${queryData?.page} - sorted for ${queryData?.sort} - ${queryData?.perpage} results per page`

  // TypeScript required return  params are not infered if set in function logic then given back on the same object.
  // So we make a new opject and set the changed required params for return using the spread operator.
  return {
    ...filterData,
    event: filterData?.event || 'filter_interaction',
    filter_existing: filterData.filter_existing?.toLowerCase() || undefined,
    core_event: filterData.core_event || 'no',
  }
}

// Create the expand/collapse analytics event log here as a function passed to FacetGroupCollapse
// This way we pass one function to call onClick with data instead of adding many more analytics only props to that component.
// Needs to be used in a few Facet components so it lives here instead of near a specific expand or collapse.
export const logExpandCollapseEvent = (
  state: string,
  toggledCat: string,
  toggleElText?: string
): void => {
  const accordionPayload = buildFacetEventPayload({
    action: `${state} filter`,
    component: 'left rail',
    element_type: 'accordion',
    element_text: toggleElText || undefined,
    filter_category: toggledCat,
  })

  sendEvent({ payload: accordionPayload })
}
