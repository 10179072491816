import React from 'react'
import { useIntl } from 'react-intl'
import { ButtonBase, ButtonBaseProps } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CaretDownIcon from '@src/icons/CaretDownIcon'
import messages from '@utils/messages'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  showMoreLess: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  icon: {
    width: 9,
    height: 5,
    marginLeft: theme.spacing(2),
  },
  iconExpanded: {
    transform: 'scaleY(-1)',
  },
}))

interface ShowMoreLessButtonProps extends ButtonBaseProps {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  expandedText?: React.ReactNode
  collapsedText?: React.ReactNode
}

const ShowMoreLessButton: React.FC<ShowMoreLessButtonProps> = ({
  expanded,
  setExpanded,
  expandedText,
  collapsedText,
  className,
  ...buttonBaseProps
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <ButtonBase
      onClick={() => setExpanded(!expanded)}
      aria-expanded={expanded}
      className={clsx(classes.showMoreLess, className)}
      {...buttonBaseProps}
    >
      {!expanded ? (
        <>
          <span>{collapsedText || formatMessage(messages.SHOW_MORE)}</span>
          <CaretDownIcon className={classes.icon} />
        </>
      ) : (
        <>
          <span>{expandedText || formatMessage(messages.SHOW_LESS)}</span>
          <CaretDownIcon className={clsx(classes.icon, classes.iconExpanded)} />
        </>
      )}
    </ButtonBase>
  )
}
export default ShowMoreLessButton
