import React from 'react'
import { useIntl } from 'react-intl'
import { Formik, Form, Field } from 'formik'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Button,
  RadioGroup,
  Divider,
  Link as MUILink,
  FormControlLabel,
} from '@material-ui/core'
import messages from '@utils/messages'
import LiquidInput from '@src/components/LiquidInput'
import { StructureSearchType } from '@src/types/graphql-types'
import RadioButton from '@src/components/RadioButton'
import StructureSearchCustomCheckbox from './StructureSearchCustomCheckbox'

const useStyles = makeStyles((theme: Theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      flexDirection: 'column !important',
    },
  },
  searchOptionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },
  searchTypeContainer: {
    marginTop: theme.spacing(7),
  },
  searchOptionsContainer: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 768px)': {
      flexDirection: 'column !important',
    },
  },
  similarityInput: {
    width: '4rem',
    height: '2.5rem',
    marginLeft: theme.spacing(-13),
  },
  percent: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  buttonSearch: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(4),
    '@media (max-width: 768px)': {
      width: '100%',
      marginRight: '0',
    },
  },
  buttonReset: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(7),
    color: theme.palette.primary.main,
    fontWeight: 900,
    border: '0',
    right: '0',
    '&:hover': { background: 'none' },
    '@media (max-width: 768px)': {
      width: '100%',
      position: 'initial',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(13),
  },
  divider: {
    margin: '0px 30px',
  },
  helpLink: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'rgb(27, 102, 170)',
  },
  mobileView: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 768px)': {
      flexDirection: 'column !important',
    },
  },
  searchTypeMobileView: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 768px)': {
      alignItems: 'flex-end',
    },
  },
  buttonReverse: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(4),
    '@media (max-width: 768px)': {
      flexDirection: 'column-reverse',
    },
  },
}))

interface FormikInitialValues {
  searchType: string
  similarityPercentage: string
}
interface StructureSearchFormProps {
  initialValues: FormikInitialValues
  onSubmit: (searchInput) => void
  handleSimliarityPercentageChange: (e) => void
  handleRadioChange: (e, setFieldValue, similarityPercentage) => void
  resetDrawState: (resetForm) => void
  resetDisabled: boolean
  searchDisabled: boolean
  isModal: boolean
}

const StructureSearchForm: React.FC<StructureSearchFormProps> = ({
  initialValues,
  onSubmit,
  resetDisabled,
  searchDisabled,
  resetDrawState,
  handleRadioChange,
  handleSimliarityPercentageChange,
  isModal,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, resetForm, setFieldValue }) => {
        const noSimimilarityEntered =
          values.searchType === StructureSearchType.Similarity &&
          !values.similarityPercentage
        return (
          <Form aria-label="search form inputs">
            <div className={classes.mobileView}>
              <div className={classes.searchTypeContainer}>
                <div className={classes.searchOptionTitle}>
                  {formatMessage({
                    id: 'SEARCH_TYPE',
                    defaultMessage: 'Search Type',
                  })}
                </div>
                <div className={classes.searchTypeMobileView}>
                  <Field name="searchType">
                    {() => (
                      <RadioGroup
                        aria-label="search type"
                        onChange={(e) =>
                          handleRadioChange(
                            e,
                            setFieldValue,
                            values.similarityPercentage
                          )
                        }
                        value={values.searchType}
                      >
                        <div className={classes.flexRow}>
                          <FormControlLabel
                            aria-label="substructure"
                            classes={{ label: classes.label }}
                            value="substructure"
                            control={<RadioButton />}
                            label="Substructure"
                          />
                          <FormControlLabel
                            aria-label="exact"
                            classes={{ label: classes.label }}
                            value="exact"
                            control={<RadioButton />}
                            label="Exact"
                          />
                          <div className={classes.flexRow}>
                            <FormControlLabel
                              classes={{ label: classes.label }}
                              aria-label="similarity"
                              value="similarity"
                              control={<RadioButton />}
                              label="Similarity"
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    )}
                  </Field>
                  <Field name="similarityPercentage">
                    {(props) => (
                      <LiquidInput
                        {...props.field}
                        className={classes.similarityInput}
                        placeholder={'0%'}
                        type="number"
                        disabled={values.searchType !== 'similarity'}
                        onInput={handleSimliarityPercentageChange}
                      />
                    )}
                  </Field>
                  {!!values.similarityPercentage && (
                    <span className={classes.percent}>%</span>
                  )}
                </div>
              </div>
              <div className={classes.searchOptionsContainer}>
                <Divider orientation="vertical" className={classes.divider} />
                <div>
                  <div className={classes.searchOptionTitle}>
                    {formatMessage(messages.SEARCH_OPTIONS)}
                  </div>
                  <div>
                    <Field name="stereo">
                      {(props) => (
                        <FormControlLabel
                          label="Stereo"
                          classes={{ label: classes.label }}
                          control={<StructureSearchCustomCheckbox {...props} />}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.buttonReverse}>
              {isModal && (
                <MUILink
                  className={classes.helpLink}
                  href="https://docs.chemaxon.com/display/docs/Marvin+JS+FAQ"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Help/FAQs
                </MUILink>
              )}
              <Button
                variant="outlined"
                aria-label="reset"
                disableRipple={false}
                disabled={resetDisabled}
                className={classes.buttonReset}
                onClick={() => resetDrawState(resetForm)}
              >
                {formatMessage(messages.RESET)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                aria-label="submit"
                disableRipple={false}
                disabled={searchDisabled || noSimimilarityEntered}
                className={classes.buttonSearch}
                id="button--submit-structure-search"
              >
                {formatMessage(messages.SEARCH)}
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default StructureSearchForm
